<template>
	<div>
		<!-- 头部导航 -->
		<el-affix :z-index='10000'>
		  <nav-bar></nav-bar>
		</el-affix>
		<!-- 工具栏 -->
		<tool-box></tool-box>
		<!-- 分类 -->
		<div class='industry-test-wrap container'>
			<el-card class='box-card'>
				<ul >
					<li>
						<div
							class="list-title industry-title"
							:class="industryActive==index?'industryActiveStyle':''"
							v-for='(item,index) in industryList'
							@click="chooseTndustry(item,index)"
							:key='index'>
								{{item.title}}
						</div>
					</li>
					<li>
						<div
							:class="testActive==index?'testActiveStyle':''"
							class="list-title test-title"
							@click="chooseTest(item,index)"
							v-for='(item,index) in testList'
							:key='index'>
								{{item.name}}
						</div>
					</li>
				</ul>
			</el-card>
		</div>
		
		<div 
			class='industry-test-content container'
			v-loading="courseLoading">
			<div v-if='courseList.length > 0'>
				<el-row :gutter="15">
					<el-col 
						:span='6'
						style='margin-bottom:15px'
						v-for='(item,index) in courseList'
						:key='index'>
						<!-- 班型列表模板 -->
						<comboclass-Item :item='item'></comboclass-Item>
					</el-col>
				</el-row>
			</div>
			<div class='industry-test-content container' v-else>
				<el-empty description="暂无课程"></el-empty>
			</div>
		</div>
		
		<!-- footer -->
		<web-footer></web-footer>
	</div>
</template>

<script>
	import NavBar from '@/components/page/NavBar'
	import ToolBox from '@/components/toolbox'
	import WebFooter from '@/components/page/WebFooter'
	import comboclassItem from './components/ComboClassItem'
	import {
		getComboBySpec,
		getProSpec} from '@/api/course'
	export default{
		name:'course',
		props:['pid','id'],
		data(){
			return {
				industryList:[],//行业分类
				industryActive:0,
				testList:[],//考试分类
				testActive:0,
				industryId:'',
				testId:'',
				courseList:[],
				courseLoading:true
			}
		},
		components: {
		  NavBar,
		  ToolBox,
		  WebFooter,
		  comboclassItem
		},
		watch:{
			$route(to,from){
				console.log(to,from);
				if(from.path=='/course'){//课程中心进入
				   getProSpec().then(res=>{
						if(res.code==1){
							this.industryActive = 0;
							this.testActive = 0;
							this.industryList = res.data;
							this.testList  = this.industryList[this.industryActive].children;
							this.industryId = this.industryList[this.industryActive].id;
							this.testId = this.testList[this.testActive].id;
							this.getCourseList(this.testId);
						}
				   });
				}else{//科目列表进入
					getProSpec().then(res=>{
						if(res.code==1){
							this.industryList = res.data;
							this.industryList.forEach((item,index)=>{
								if(item.id==this.pid){
									this.industryId = item.id;
									this.industryActive = index;
								}
							});
							this.industryList.forEach((item,index)=>{
								item.children.forEach((list,lindex)=>{
									if(this.id==list.id){
										this.testActive = lindex;
										this.testList =  item.children;
										this.testId = list.id;
									}
								})
							});
							this.getCourseList(this.testId);
						}
					});
				}
			},
			industryId(){
			  this.$router.push({
				path:'/course/'+ this.industryId + '/'+this.testId
			  });
			},
			testId(){
			  this.$router.push({
				path:'/course/'+ this.industryId + '/'+this.testId
			  });
			}	
		},
		created() {
			
			if(!this.id&&!this.pid){//课程中心进入
				getProSpec().then(res=>{
				   if(res.code==1){
					 this.industryActive = 0;
					 this.testActive = 0;
					 this.industryList = res.data;
					 this.testList  = this.industryList[this.industryActive].children;
					 
					 this.industryId = this.industryList[this.industryActive].id;
					 this.testId = this.testList[this.testActive].id;
					 
					 this.getCourseList(this.testId);
				   }
				});
			}else{
				getProSpec().then(res=>{
					if(res.code==1){
						this.industryList = res.data;
						
						this.industryList.forEach((item,index)=>{
							if(item.id==this.pid){
								 this.industryId = item.id;
								 this.industryActive = index;
							}
						});
						this.industryList.forEach((item,index)=>{
							item.children.forEach((list,lindex)=>{
								if(this.id==list.id){
								   this.testActive = lindex;
								   this.testList =  item.children;
								   this.testId = list.id;
								}
							})
						});
						this.getCourseList(this.testId);
					}
				});
			}
		},
		methods:{
			getCourseList(testid){//课程列表
			  getComboBySpec({
			    spec_id:testid
			  }).then(res=>{
			    if(res.code==1){
					this.courseLoading = false;
					this.courseList = res.data.comboclass.filter((fitem,findex)=>{
						return fitem.status==1
					});
			    }
			  });
			},
			chooseTndustry(item,index) {//切换行业
				this.industryActive = index;
				this.testActive = 0;
				this.testList = item.children;
				this.industryId = item.id;
				this.testId = this.testList[this.testActive].id;
			},
			chooseTest(item,index) {//切换考试
				this.testActive = index;
				this.testId = item.id;
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.industry-test-wrap{
		margin-top:20px;
		ul{
			padding:10px 20px;
			li{
				display: flex;
				flex-direction: row;
				align-items: center;
				height:70px;
				border-bottom:1px solid $info-color;
				.list-title{
					font-size:16px;
					margin-right:30px;
				}
				.industry-title{
					font-size:18px;
					margin-right:70px;
				}
				.industry-title.industryActiveStyle{
					color:$theme-color;
					font-weight: bold;
				}
				.test-title.testActiveStyle{
					color:$theme-color;
				}
				
			}
			li:last-child{
				border-bottom:none;
			}
		}
	}
	.industry-test-content{
		margin-top:15px;
		margin-bottom: 50px;
	}
</style>
